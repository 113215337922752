import { computed, watch } from "@vue/runtime-core";
import config from 'config'

function getValue(store,i18n,data)
{
    if(typeof data === 'string'){
        return data;
    }    
    if(typeof data !== 'object'){
        return '';
    }
    if(Object.prototype.hasOwnProperty.call(data,'text')){
        return data.text;
    }
    if(Object.prototype.hasOwnProperty.call(data,'i18n')){
        return i18n.t(data.i18n);
    }
    if(Object.prototype.hasOwnProperty.call(data,'store')){
        return store.getters[data.store];
    }
    return '';
}

function setAttribute(attr,value) {
    if(!value){
        return;
    }

    const tag = document.createElement('meta');

    tag.setAttribute('name',attr);
    tag.setAttribute('content', value);
    
    tag.setAttribute('data-page-controlled', '');

    document.head.appendChild(tag)
}

function setCanonical(link){
    const tag = document.createElement('link');

    tag.setAttribute('rel','canonical');
    tag.setAttribute('href',link);
    tag.setAttribute('data-page-controlled', '');

    document.head.appendChild(tag)
}

function setSocialProperty(attr,value) {
    if(!value){
        return;
    }

    const tag = document.createElement('meta');

    tag.setAttribute('property',attr);
    tag.setAttribute('content', value);
    
    tag.setAttribute('data-page-controlled', '');

    document.head.appendChild(tag)
}

export default function({i18n,store,dynamicHead = null}) {
    const page = computed(() => store.getters['page/getPage']); 

    watch(() => page.value,() => {
        Array.from(document.querySelectorAll('[data-page-controlled]')).map(el => el.parentNode.removeChild(el));
        console.clear();

        if(!page.value){
            return;
        }
        let sitename =  getValue(store,i18n,config.title);
        let title = sitename;
        if(page.value.title){
            title = page.value.title + ' | ' + sitename;
        }
        if(page.value.title.toLowerCase() !== 'home'){
            document.title = title;
        } else {
            document.title = sitename;
        }

        // meta seo
        setAttribute('description',page.value.seo.description);
        // setAttribute('keywords',page.value.seo.keywords);

        // link canonical
        let lang = '';
        switch(page.value.language){
            case 'cs-CZ':
                lang = 'cz/';
                break;
            default:
            {
                let temp = page.value.language.split('-');
                if(temp.length > 0){
                    lang = temp[0]+'/';
                }
            }
        }
        let link = location.origin+'/';
        if(page.value.homepage){
            link += lang;
        } else if(page.value.seo.url){
            link += lang+page.value.seo.url;
        } else {
            link += lang+i18n.t('url.page')+'/'+page.value.code;
        }
        setCanonical(link);

        // social media tags
        setSocialProperty('og:type','website');
        setSocialProperty('og:title',title);
        setSocialProperty('og:url',link);
        setSocialProperty('og:description',page.value.seo.description);
        if(page.value.seo.og_image_source){
           setSocialProperty('og:image',page.value.seo.og_image_source.url); 
        } else if(page.value.image) {
           setSocialProperty('og:image',page.value.image.url); 
        }
        setSocialProperty('og:site_name',sitename);
        setSocialProperty('og:locale',page.value.language);

        if(page.value.seo.twitter_image_source){
           setSocialProperty('twitter:image',page.value.seo.twitter_image_source.url); 
        } else if(page.value.image || page.value.seo.og_image_source) {
           setSocialProperty('twitter:card','summary_large_image'); 
        }
        //setSocialProperty('twitter:creator','@agneweb'); 
        // todo social meta tags
        
        if(dynamicHead){
            dynamicHead();
        }
    })

}
