<template>
    <router-view v-bind="$attrs" keep-alive/>
</template>

<script>
import config from 'config'
import setupHeader from "@common/composable/header";
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import PageMeta from '@common/composable/cms_app/PageMeta';

export default {
    name: "App",
    components: {
    },
    setup(){
        const router = useRouter();
        const i18n = useI18n();
        const store = useStore();

        const fetchProject = () => {
            store.dispatch("project/fetchProject")
        };
        fetchProject();

        const {  setMetaData,appendToHead  } = setupHeader(router,i18n,store,config);
        setMetaData(router,i18n,store,config);

        const dynamicHead = () => {
            if(localStorage.getItem("cookie:analytics") === "true"){
                appendToHead("script","https://www.googletagmanager.com/gtag/js?id=G-91RCPRBP7K",{async: '','data-page-controlled': ''})
                appendToHead("script","window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-91RCPRBP7K');",{'data-page-controlled': ''})
            } 
        }
     
        PageMeta({i18n,store,dynamicHead})
    }
}
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Orbitron:wght@700&display=swap'); */
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v17/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1ny_CmBoWgz.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    color: #344b1b;
    color: var(--dark-color);
    background-color:  #E2E8D4;
    background-color: var(--light-color);
}


:root{
    --primary-color: #177542;
    --dark-color: #344b1b;/*#125E34;*/ 
    --light-color: #E2E8D4;
    --secondary-color:#f1bd80;
    --button-color:#c9c9c9;
    --border-color:#bbbbbb;
    --transition-ease: 0.5s ease;
    --transition-opacity: opacity var(--transition-ease);

    --button-size: 1.8rem;

    --primary-font: Avenir, Helvetica, Arial, sans-serif;

    --bg-pattern: linear-gradient(rgb(52 75 27 / 20%) 0.6000000000000001px, transparent 0.6000000000000001px), 
        linear-gradient(to right, rgb(52 75 27 / 20%) 0.6000000000000001px, transparent 0.6000000000000001px),
        radial-gradient(transparent,rgb(52 75 27 / 20%));
    --bg-pattern-size: 
    8px 8px,
    8px 8px,
    8px 8px;
}


*{
    box-sizing: border-box;
}


/* implementation for old browsers*/
article, aside, footer, header, nav, section {
    display: block;
}

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a{
    text-decoration: none;
    transition: var(--transition-opacity);
    color: #002aa2;
}
a:hover{
    opacity: 0.8;
}
ul{
    list-style-type: none;
}
.page-container{
    margin: 0 auto;  
    max-width: 60rem;
    position: relative;
}

/* .grecaptcha-badge { 
    visibility: hidden;
} */
</style>
