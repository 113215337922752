<template>
    <transition appear :name="transitionName">
        <div class="Cookie" :class="[containerPosition, cookieTheme]" v-if="isOpen">
            <div class="Cookie__content">
                <slot name="message">{{ message }}</slot>
                <a :target="target" :href="buttonLink" v-if="externalButtonLink" :class="buttonClass">{{ buttonLinkText }}</a>
                <RouterLinkLocal :link="buttonLink" v-if="internalButtonLink" :class="buttonClass">{{ buttonLinkText }}</RouterLinkLocal>
            </div>
            <div class="selection">
                <div v-for="cookieType of cookieData" :key="cookieType.name">
                    <input type="checkbox" :name="cookieType.name" :id="'ckeckbox_'+cookieType.name" :checked="cookieType.checked" :disabled="cookieType.disabled" @change="cookieType.checked = !cookieType.checked">
                    <label for="cookieType.name" >{{ cookieType.text }}</label>
                </div>
                
            </div>
            <div class="Cookie__buttons">
                <BaseButton :class="buttonClass" class="btn-default" @click="acceptSelected">{{ buttonAcceptSelected }}</BaseButton>
                <BaseButton :class="buttonClass" class="btn-danger" @click="declineAll">{{ buttonDeclineText }}</BaseButton>
                <BaseButton :class="buttonClass" class="btn-success" @click="acceptAll">{{ buttonText }}</BaseButton>
            </div>
        </div>
        <div class="Cookie-icon" :class="[cookieTheme]" v-else @click="isOpen = true">
            <img src="../../assets/cookie.webp" alt="Cookie" width="50" height="50">
        </div>
    </transition>
</template>

<script>
import * as Cookie from 'tiny-cookie'
import { useI18n } from 'vue-i18n'

const STORAGE_TYPES = {
    local: 'localStorage',
    cookies: 'cookies'
}
// original source https://github.com/apertureless/vue-cookie-law/blob/develop/src/components/CookieLaw.vue
// todo - use composable api
export default {
    name: 'CookieBar',
    props: {
        buttonText: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.accept_all');
            } 
        },
        buttonDeclineText: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.decline_all');
            } 
        },
        buttonAcceptSelected: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.accept_selected');
            } 
        },
        buttonLink: {
            type: String,
            required: false
        },
        buttonLinkType: {
            type: String,
            required: false,
        },
        buttonLinkText: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.more_info');
            } 
        },
        buttonLinkNewTab: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('cms.cookie_consent');
            } 
        },
        cookieTypes: {
            type: Array,
            default: () => {
                const i18n = useI18n();
                return [
                    {
                        name: "necessary",
                        text: i18n.t("controls.cookie_necessary"),
                        disabled: true,
                        checked: true
                    },
                    {
                        name: "analytics",
                        text: i18n.t("controls.cookie_analytics"),
                        disabled: false,
                        checked: false
                    },
                    // {
                    //     name: "marketing",
                    //     text: i18n.t("controls.cookie_marketing"),
                    //     disabled: false,
                        // checked: false
                    // },
                ]
            }
        },
        theme: {
            type: String,
            default: 'base'
        },
        /**
         * Cookie Container position
         * bottom, top
         * @type {Object}
         */
        position: {
            type: String,
            default: 'bottom'
        },
        /**
         * Transition name has following possibilities
         * slideFromBottom
         * slideFromTop
         * fade
         * @type {Object}
         */
        transitionName: {
            type: String,
            default: 'slideFromBottom'
        },
        buttonClass: {
            type: String,
            default: 'Cookie__button'
        },
        buttonDeclineClass: {
            type: String,
            default: 'Cookie__button--decline'
        },
        buttonAcceptSelectedClass: {
            type: String,
            default: 'Cookie__button--accept-selected'
        },
        storageName: {
            type: String,
            default: 'cookie:'
        },
        storageNameSelected: {
            type: String,
            default: 'cookie:selected'
        },
        storageType: {
            type: String,
            default: STORAGE_TYPES.local
        },
        cookieOptions: {
            type: Object,
            default: () => {},
            required: false
        }
    },
    data () {
        let cookieData = this.cookieTypes;

        return {
            supportsLocalStorage: true,
            isOpen: false,
            cookieData,
        }
    },
    computed: {
        containerPosition () {
            return `Cookie--${this.position}`
        },
        cookieTheme () {
            return `Cookie--${this.theme}`
        },
        externalButtonLink () {
            return typeof this.buttonLink === 'string' && this.buttonLink.length && this.buttonLinkType === "external"
        },
        internalButtonLink () {
            return typeof this.buttonLink === 'string' && this.buttonLink.length && this.buttonLinkType === "internal"
        },
        target () {
            return this.buttonLinkNewTab ? '_blank' : '_self'
        },
        canUseLocalStorage () {
            return this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage
        }
    },
    created () {
        if (this.storageType === STORAGE_TYPES.local) {
            // Check for availability of localStorage
            try {
                const test = '__vue-cookielaw-check-localStorage'
                if (typeof window !== 'undefined') {
                  window.localStorage.setItem(test, test)
                  window.localStorage.removeItem(test)
                }
            } catch (e) {
                console.info('Local storage is not supported, falling back to cookie use')
                this.supportsLocalStorage = false
            }
        }
        if (!this.getSelected()) {
            this.isOpen = true
        }
    },
    // mounted () {
    //     if (this.isAccepted()) {
    //         this.$emit('accept')
    //     }
    // },
    methods: {
        setToStorage (name, value) {
            if (this.canUseLocalStorage) {
                localStorage.setItem(name, value)
            } else {
                Cookie.set(name, value, { ...this.cookieOptions, expires: '1Y' })
            }
        },
        getFromStorage (name) {
            let value = false
            if (this.canUseLocalStorage) {
                value = localStorage.getItem(name)
            } else {
                value = Cookie.get(name)
            }
            if (typeof value === 'string') {
                value = JSON.parse(value)
            }
            return value
        },
        setSelected () {
            this.setToStorage(this.storageNameSelected, true);
        },
        setAcceptSelected () {
            for(let cookie of this.cookieData){
                if(cookie.disabled){
                    continue;
                }
                this.setToStorage(this.storageName+cookie.name, cookie.checked);
            }
        },
        setAcceptedAll () {
            for(let cookie of this.cookieData){
                if(cookie.disabled){
                    continue;
                }
                cookie.checked = true;
                this.setToStorage(this.storageName+cookie.name, true);
            }
        },
        setDeclinedAll () {
            for(let cookie of this.cookieData){
                if(cookie.disabled){
                    continue;
                }
                cookie.checked = false;
                this.setToStorage(this.storageName+cookie.name, false);
            }
        },
        getSelected () {
            let visited = this.getFromStorage(this.storageNameSelected);
            if(!(visited === null || visited === undefined)){
                for(let cookie of this.cookieData){
                    if(cookie.disabled){
                        continue;
                    }
                    cookie.checked = this.isAccepted(cookie.name);
                }
                return true;
            }
            return false;
        },
        isAccepted (name) {
            return this.getFromStorage(this.storageName+name);
        },
        acceptAll () {
            this.setSelected()
            this.setAcceptedAll()
            this.isOpen = false
            // this.$emit('accept')
        },
        acceptSelected () {
            this.setSelected()
            this.setAcceptSelected()
            this.isOpen = false
            // this.$emit('accept')
        },
        close () {
            this.isOpen = false
            this.$emit('close')
        },
        declineAll () {
            this.setSelected()
            this.setDeclinedAll()
            this.isOpen = false
            // this.$emit('decline')
        },
        revoke () {
            if (this.canUseLocalStorage) {
                localStorage.removeItem(this.storageName)
            } else {
                Cookie.remove(this.storageName)
            }
            this.isOpen = true
            this.$emit('revoke')
        },
        open () {
            if (!this.getSelected()) {
                this.isOpen = true
            }
        }
    }
}
</script>

<style scoped>
.Cookie {
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 9999;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-direction: column;
    padding: 1.250rem;
    background: #F1F1F1;
    color: #232323;
    gap: 1.5rem;
    
    background: var(--backgroundColor);
    color: var(--fontColor);
}
.Cookie a {
    color: #232323;
    color: var(--fontColor);
    text-decoration: underline;
}
.Cookie > * {
    align-self: center;
}

.Cookie-icon {    
    position: fixed;
    right: 10px;
    bottom: 10px;
    color: white;
    background: var(--backgroundColor);
    z-index: 1000;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    line-height: 10px;
}
.Cookie-icon img{ 
    width: 30px;
    height: 30px;
}
.selection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.Cookie__buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.selection input[type=checkbox]{
    width: 1rem;
    height: 1rem;
    margin: 0 0.2rem;
}
@media screen and (min-width: 600px) {
    /* .Cookie {
        flex-flow: row;
    } */
    .Cookie > * {
        margin: 0;
    }
    .Cookie__buttons{
        flex-direction: row;
    }
    .Cookie-icon img{ 
        width: 50px;
        height: 50px;
    }
}
.Cookie--top {
    top: 0;
    left: 0;
    right: 0;
}
.Cookie--bottom {
    bottom: 0;
    left: 0;
    right: 0;
}


.Cookie__button {
    /* cursor: pointer;
    align-self: center; */
    white-space: nowrap;
    padding: 0.625rem 3.125rem;
    /* border: 0; */
    font-size: 1rem;
    /* color: #fff;
    color: var(--buttonFontColor);
    border-radius: 0;
    border-radius: var(--buttonRadius);
    background: #97D058;
    background: var(--buttonBackgroundColor); */
}
/* .Cookie__button:hover{
    background: darken(#97D058, 10%);
    background: darken(var(--buttonBackgroundColor), 10%);
} */
.Cookie__button--decline {
    cursor: pointer;
    align-self: center;
    white-space: nowrap;
    background: transparent;
    padding: 0.625em 3.125em;
    border: 0;
    font-size: 1em;
    color: darken(#F1F1F1, 50%);
    color: darken(var(--backgroundColor), 50%);
    border-radius: 0;
    border-radius: var(--buttonRadius);
}
/* .Cookie__button--decline:hover{
    background: darken(#F1F1F1, 15%);
    background: darken(var(--backgroundColor), 15%);
} */
.Cookie--base{
    --backgroundColor:  #F1F1F1;
    --fontColor: #232323;
    --buttonBackgroundColor: #97D058;
    --buttonFontColor: #fff;
    --buttonRadius: 0;
}
.Cookie--rounded{
    --backgroundColor:  #F1F1F1;
    --fontColor: #232323;
    --buttonBackgroundColor: #97D058;
    --buttonFontColor: #fff;
    --buttonRadius: 1rem;
}
.Cookie--blood-orange{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #E76A68;
    --buttonFontColor: #fff;
    --buttonRadius: 0;
}
.Cookie--blood-orange--rounded{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #E76A68;
    --buttonFontColor: #fff;
    --buttonRadius: 1rem;
}
.Cookie--dark-lime{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #E76A68;
    --buttonFontColor: #fff;
    --buttonRadius: 0;
}
.Cookie--dark-lime--rounded{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #97D058;
    --buttonFontColor: #fff;
    --buttonRadius: 1rem;
}

.slideFromTop-enter, .slideFromTop-leave-to {
    transform: translate(0px, -12.500em);
}
.slideFromTop-enter-to, .slideFromTop-leave {
    transform: translate(0px, 0px);
}
.slideFromBottom-enter, .slideFromBottom-leave-to {
    transform: translate(0px, 12.500em);
}
.slideFromBottom-enter-to, .slideFromBottom-leave {
    transform: translate(0px, 0px);
}
.slideFromBottom-enter-active,
.slideFromBottom-leave-active,
.slideFromTop-enter-active,
.slideFromTop-leave-active {
    transition: transform .4s ease-in;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to {
    opacity: 0
}
</style>